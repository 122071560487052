(function ($) {
    let $fn = $(".comp_card");

    if ($fn.length) {
        let $gallery = $fn.find("[data-gallery-list]");

        if($gallery.length){
            $gallery.each(function () {
                $(this).createGallery()
            })
        }
    }
})(jQuery);
